import React from 'react'
import Post from '../components/Post';

const Index = ({pageContext}) => 
{
    
    const out =
        <Post {...pageContext}/>

    return out

}

export default Index;
