import React , {useState} from 'react'
import { Link, navigate } from 'gatsby';
import { Swipeable } from 'react-swipeable';
import { Helmet } from 'react-helmet';

const Post = ({next,back,theme,title,content,img,publicURL,cta,og,footer}) =>
{

    typeof window !== 'undefined' && document.body.parentElement!.setAttribute('theme',theme);

    const onSwipe = (e) =>
    {
        switch (e.dir)
        {

            case 'Left': navigate(next); break;
            case 'Right': navigate(back); break;

        }
    }

    const [isOpen,setIsOpen] = useState(false);

    function onClickShareButton(e)
    {
        e.preventDefault();
        setIsOpen(!isOpen);
        return false;
    }

    const swipeOptions = 
    {
        delta:20,
        preventDefaultTouchmoveEvent: false,
        trackTouch: true,
        trackMouse: false,
        rotationAngle: 0,
    };

    const out =
        <Swipeable onSwiped={onSwipe} {...swipeOptions} className={"post post--" + theme}>
                <Helmet>
                    <title>{title}</title>
                    <link rel="canonical" href={'https://smilealldayandnight.fr' + publicURL}/>
                    <meta property="og:url" content={og.url} />
                    <meta property="og:type" content={og.type} />
                    <meta property="og:title" content={og.title} />
                    <meta property="og:description" content={og.description} />
                    <meta property="og:image" content={og.image} />
                    <meta name="description" content={og.description} />
                </Helmet>
                <div className="post__layout">
                    <div className="post__img-wrapper">
                        <div className="post__img-sizer">
                            <div className="post__img-border">
                                <picture className="post__picture">
                                    <img loading="lazy" className="post__img" src={img} alt={title}/>
                                </picture>
                                <div className="post__navbar">
                                    <ul>
                                        <li className={`post__navbar-item post__navbar-item--share post__share-list post__share-list--${isOpen ? 'open' : 'close'}`}>
                                            <a onClick={onClickShareButton} className="post__share-list__open-close btn btn--inv btn--share"></a>
                                            <ul className="post__share-list__container">
                                                <li className="post__share-list__item"><a data-trackable="1" data-share-network="facebook" className="btn btn--inv btn--facebook"/></li>
                                                <li className="post__share-list__item"><a data-trackable="1" data-share-network="whatsapp" className="btn btn--inv btn--whatsapp"/></li>
                                                <li className="post__share-list__item"><a data-trackable="1" data-share-network="messenger" className="btn btn--inv btn--messenger"/></li>
                                            </ul>
                                        </li>
                                        <li className="post__navbar-item post__navbar-item--close"><Link to={(theme == 'N' ? '/?Nuit' : '/?Jour')} className="btn btn--close"/></li>
                                        <li className="post__navbar-item post__navbar-item--next"><Link to={next == null ? publicURL : next} className={`btn btn--next btn--${next == null ? 'disabled' : 'enabled'}`}/></li>
                                        <li className="post__navbar-item post__navbar-item--back"><Link to={back == null ? publicURL : back} className={`btn btn--back btn--${back == null ? 'disabled' : 'enabled'}`}/></li>
                                    </ul>
                                </div>
                                <div className="post__img-deco">
                                    <div className="deco deco--inv"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="post__content-wrapper">
                        <div className="post__content">
                            <div className="post__content-inner">
                                <h1 className="post__title">{title}</h1>
                                <div className="post__desc">
                                    <div dangerouslySetInnerHTML={{__html:content}}/>
                                    <div className={`post__cta-wrapper post__cta-wrapper--${cta.label !== null ? 'show' : 'hide'}`}>
                                        {(() => {

                                            if (!cta.label) return
                                            
                                            const out =
                                                <a data-trackable="1" title={title} target="_blank" rel="noopener" href={cta.url} className="post__cta cta">
                                                    <span style={{display:'inline-block'}} dangerouslySetInnerHTML={{__html:cta.label}}/>
                                                </a>

                                            return out

                                        })()}
                                    </div>
                                    <div className="post__desc-footer" dangerouslySetInnerHTML={{__html:footer || ''}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </Swipeable>

    return out

}

export default Post;